import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { environment } from '@inspierty/shared/environments';

@Component({
  selector: 'inspierty-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
  constructor(private ts: Title) {}

  ngOnInit() {
    this.ts.setTitle(environment.titles.home);
  }
}
