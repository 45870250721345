import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SocialMediaLink } from '@nwk/core/footer';

@Component({
  selector: 'inspierty-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {

  public readonly socialMediaLinks: SocialMediaLink[] = [
    {
      href: 'mailto:mail@inspierty.de',
      title: 'Email us',
      iconClass: 'fas fa-envelope',
      ariaLabel: 'email-link',
    },
    {
      href: 'https://www.facebook.com/Inspierty',
      title: 'Inspierty on Facebook',
      iconClass: 'fab fa-facebook-square',
      ariaLabel: 'facebook-link',
    },
    {
      href: 'https://www.instagram.com/inspierty_music/?ref=badge',
      title: 'Inspierty on Instagram',
      iconClass: 'fab fa-instagram',
      ariaLabel: 'instagram-link',
    },
    {
      href: 'https://soundcloud.com/inspierty',
      title: 'Inspierty on Soundcloud',
      iconClass: 'fab fa-soundcloud',
      ariaLabel: 'soundcloud-link',
    },
    {
      href: 'https://www.youtube.com/c/InspiertyDe',
      title: 'Inspierty on Youtube',
      iconClass: 'fab fa-youtube',
      ariaLabel: 'youtube-link',
    },
  ];
}
