<header [routerLink]="['/']">

  <picture>
    <source srcset="./assets/images/logo.webp" type="image/webp" />
    <source srcset="./assets/images/logo.png" type="image/png" />
    <img class="header__banner" src="./assets/images/logo.png" alt="header_banner" />
  </picture>

</header>

<nav>

  <!-- mobile -->
  <div class="sm:hidden">

    <button type="menu" class="bg-primary text-white w-full h-12" (click)="menuVisible = !menuVisible">
      <i class="fas fa-bars text-xl"></i>
    </button>

    <ng-container *ngIf="menuVisible">

      <ng-template *ngTemplateOutlet="navList"></ng-template>

    </ng-container>

  </div>

  <!-- desktop -->
  <div class="hidden sm:block">

    <ng-template *ngTemplateOutlet="navList"></ng-template>

  </div>

</nav>

<ng-template #navList>

  <div class="bg-white w-full z-50 flex flex-col flex-nowrap content-center items-center justify-around shadow-lg absolute sm:flex-row sm:static">

    <ng-container *ngFor="let node of navNodes">

      <a [routerLink]="node.url" [routerLinkActive]="[node.activeClass]" [routerLinkActiveOptions]="{
          paths: 'exact',
          queryParams: 'subset',
          matrixParams: 'ignored',
          fragment: 'exact'
        }" [title]="node.label"
        class="h-12 w-full flex content-center items-center justify-center hover:bg-primary text-primary hover:text-white">
        {{ node.label }}
      </a>

    </ng-container>

  </div>

</ng-template>
