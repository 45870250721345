import { Environment } from '../interfaces/environment';

export const environment: Environment = {
  titles: {
    home: 'Inspierty',
    about: 'Inspierty | Über',
    live: 'Inspierty | Live',
    music: 'Inspierty | Musik',
    media: 'Inspierty | Media',
    impressum: 'Inspierty | Impressum',
  },
};
