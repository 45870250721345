<div class="bg-primary text-white w-full">

  <div class="h-12 flex flex-row flex-nowrap content-center justify-around items-center">

    <ng-container *ngFor="let link of socialMediaLinks">

      <a rel="noopener" target="_blank" [href]="link.href" [title]="link.title" class="text-2xl">
        <i [class]="link.iconClass" [attr.aria-label]="link.ariaLabel"></i>
      </a>

    </ng-container>

  </div>

</div>

<div class="bg-primary-dark text-white w-full">

  <div class="h-12 flex flex-row flex-nowrap content-center justify-around items-center">

    <span>&copy; Inspierty</span>

    <a [routerLink]="['/impressum']" title="Impressum">
      Impressum
    </a>

  </div>

</div>
