import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { GalleryModule } from 'ng-gallery';

import '@angular/common/locales/global/de';

import { API_CONFIGURATION } from '@nwk/shared/api';
import { HttpLoaderFactory } from '@inspierty/shared/utils';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

const ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    loadChildren: () =>
      import('@inspierty/ui/about').then((m) => m.InspiertyUiAboutModule),
  },
  {
    path: 'live',
    loadChildren: () =>
      import('@inspierty/ui/live').then((m) => m.InspiertyUiLiveModule),
  },
  {
    path: 'music',
    loadChildren: () =>
      import('@inspierty/ui/music').then((m) => m.InspiertyUiMusicModule),
  },
  {
    path: 'media',
    loadChildren: () =>
      import('@inspierty/ui/media').then((m) => m.InspiertyUiMediaModule),
  },
  {
    path: 'impressum',
    loadChildren: () =>
      import('@inspierty/ui/impressum').then(
        (m) => m.InspiertyUiImpressumModule
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  declarations: [AppComponent, HomeComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {
      preloadingStrategy: PreloadAllModules,
      // enableTracing: true,
      scrollPositionRestoration: 'top'
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    GalleryModule.withConfig({
      thumbMode: 'free',
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: API_CONFIGURATION,
      useValue: {
        url: 'https://inspierty.de/cms/wp-json/',
      },
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
