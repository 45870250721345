import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'inspierty-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  constructor(private readonly _translateService: TranslateService) {
    this._translateService.use(
      this._translateService.getBrowserLang() ||
        (<any>window).navigator.language.substring(0, 2)
    );
  }
}
