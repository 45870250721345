import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationNode } from '@nwk/core/navigation';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'inspierty-header',
  templateUrl: './header.component.html',
  styles: [
    `.active {
      font-weight: bold;
      text-decoration: underline;
    }`
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnDestroy {

  public readonly navNodes: NavigationNode[] = [
    {
      label: 'Home',
      url: ['/'],
      activeClass: 'active',
      exact: true,
    },
    {
      label: 'Über',
      url: ['/about'],
      activeClass: 'active',
    },
    {
      label: 'Live',
      url: ['/live'],
      activeClass: 'active',
    },
    {
      label: 'Musik',
      url: ['/music'],
      activeClass: 'active',
    },
    {
      label: 'Media',
      url: ['/media'],
      activeClass: 'active',
    },
  ];

  public menuVisible = false;

  private readonly subscriptions = new Subscription();

  constructor(private readonly router: Router) {
    this.subscriptions.add(
      this.router.events
        .pipe(filter((e) => e instanceof NavigationEnd))
        .subscribe(() => {
          if (this.menuVisible) {
            this.menuVisible = false;
          }
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
