import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiConfiguration } from '../interfaces/api-config';
import { API_CONFIGURATION } from '../tokens/api-config.token';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  protected abstract get endpoint(): string;

  constructor(
    @Inject(API_CONFIGURATION) protected readonly apiConfig: ApiConfiguration,
    protected readonly http: HttpClient
  ) {}
}
